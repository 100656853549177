import React, { FC } from 'react';
import Layout from '../layout/layout';
import { Helmet } from 'react-helmet';
import { OverviewTab } from './overview';
import { HeroHeader } from './common/HeroHeader';
import { Subnav } from './common/Subnav';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { TopNav } from '../layout';
import { graphql } from 'gatsby';
import { MainFooter } from '../layout/footer/footer.interfaces';
import { ResourceLinkInterface } from './common/ResourcesGrid';
import { BladeSubNav } from '../common/blades/BladeSubNav';
import { BlogDataInterface, FullContentInterface } from './interfaces/welcome-center.interface';
import { ContentfulBladeHero } from '../../contentful/content-types/blades/blade-hero-animation';
import { ContentfulBladeSubNav } from '../../contentful/content-types/blades/blade-sub-nav';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';
export const query = graphql`
  query($locale: String!) {
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    fullContent: bladeFullContent(
      contentfulId: { eq: "2ieCqXUDGcD2sUCoJbxUn4" }
      locale: { eq: $locale }
    ) {
      copy
      title
    }
    heroSection: bladeHeroAnimation(
      contentfulId: { eq: "1N7V2AlkNnzQv0q9JBZi6w" }
      locale: { eq: $locale }
    ) {
      ...bladeHeroAnimationFragment
    }
    resourceGrid: bladeInfoBlocks(
      contentfulId: { eq: "5qZqpdha26WtvVPri2e2t7" }
      locale: { eq: $locale }
    ) {
      title
      relatedInfoBlocks {
        title
        description
        linkText
        linkUrl
      }
    }
    subnav: bladeSubNav(contentfulId: { eq: "1e0eITYdkm5xNNEvoFpLzK" }, locale: { eq: $locale }) {
      ...subnavFragment
    }
    fromBlog: bladeBlogLinks(
      contentfulId: { eq: "fRI1pft8GCIZschPMPn0b" }
      locale: { eq: $locale }
    ) {
      title
      firstBlurbTitle
      firstBlurbBody
      firstBlurbUrl
      firstBlurbLinkText

      secondBlurbTitle
      secondBlurbBody
      secondBlurbUrl
      secondBlurbLinkText

      thirdBlurbTitle
      thirdBlurbBody
      thirdBlurbUrl
      thirdBlurbLinkText
    }
  }
`;

export interface WelcomeCenterProps extends GatsbyTemplateProps<AppContextProps> {
  data: {
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    footerData: MainFooter;
    navAbTestData: TopNav;
    fullContent: FullContentInterface;
    heroSection: ContentfulBladeHero;
    subnav: ContentfulBladeSubNav;
    resourceGrid: {
      relatedInfoBlocks: ResourceLinkInterface[];
      title: string;
    };
    fromBlog: BlogDataInterface;
  };
}

const Overview: FC<WelcomeCenterProps> = ({ data, pageContext }) => {
  return (
    <Layout
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      pageContext={pageContext}
      topNavData={data.navData}
      topNavAbTestData={data.navAbTestData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Welcome Center | Cloudflare</title>
      </Helmet>

      <HeroHeader title={data.fullContent.title} subtitle={data.fullContent.copy}></HeroHeader>

      <BladeSubNav blade={data.subnav} pagePrefix={'/'} />
      <OverviewTab
        heroSection={data.heroSection}
        resourceGrid={{
          title: data.resourceGrid.title,
          resources: data.resourceGrid.relatedInfoBlocks,
        }}
        fromBlog={data.fromBlog}
      ></OverviewTab>
    </Layout>
  );
};

export default Overview;
